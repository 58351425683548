import {useState} from "react";
import {css} from "@emotion/react";

import {onDesktop, p} from "@pg-design/helpers-css";
import {IInfiniteScrollItemProps, IInfiniteScrollListProps, InfiniteScrollList, useInfiniteList} from "@pg-design/infinite-list";
import {SystemModal} from "@pg-design/modal";

import {IPropertyListProperty} from "../../../property/types/PropertyListProperty";
import {useAppDispatch} from "../../../store/hooks";
import {IUseOfferDetailPropertiesQuery} from "../../hooks/use_offer_modal_properties";
import {loadNextPage} from "../../state/offer_modals_slice";
import {IOfferModalName} from "../../types/OfferModalName";
import {IOfferModalOffer} from "../../types/OfferModalOffer";
import {OfferModalPropertiesList} from "./properties_list/OfferModalPropertiesList";
import {OfferModalPropertiesListItem} from "./properties_list/OfferModalPropertiesListItem";

interface IProps {
    modalName: IOfferModalName;
    propertiesQuery: IUseOfferDetailPropertiesQuery;
    offer: IOfferModalOffer;
    intersectionContainerId: string;
}

export function OfferModalTilesList(props: IProps) {
    const {modalName, propertiesQuery, offer, intersectionContainerId} = props;

    const dispatch = useAppDispatch();
    const [isIntersecting, setIsIntersecting] = useState(false);
    const infiniteList = useInfiniteList(
        propertiesQuery.queryData,
        () => {
            dispatch(loadNextPage({modalName}));
        },
        isIntersecting,
        propertiesQuery.isUninitialized
    );

    return (
        <SystemModal.Content css={resetPaddingOnDesktop}>
            <InfiniteScrollList
                listComponent={OfferModalPropertiesList}
                itemComponent={getItemComponent(offer)}
                infiniteList={infiniteList}
                listIntersectionContainerId={intersectionContainerId}
                handleIsIntersecting={(isIntersecting) => {
                    setIsIntersecting(isIntersecting);
                }}
            />
        </SystemModal.Content>
    );
}
/*
    Utils
 */
function getItemComponent(offer: IOfferModalOffer): IInfiniteScrollListProps<IPropertyListProperty>["itemComponent"] {
    return (props: IInfiniteScrollItemProps<IPropertyListProperty>) => {
        const {item} = props;

        return <OfferModalPropertiesListItem property={item} offer={offer} />;
    };
}

/*
    Styles
 */
const resetPaddingOnDesktop = css`
    ${onDesktop(css`
        ${p(0)}
    `)}
`;
