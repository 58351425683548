import {ReactNode} from "react";
import {css, SerializedStyles, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {calculateRemSize as crs, mb} from "@pg-design/helpers-css";
import {ChevronDownIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {ISortValue} from "../../../types/ISortOption";
import {IPropertySortableField} from "../../interfaces/IPropertySortableField";
import {IPropertySortOption} from "../../interfaces/IPropertySortOption";
import {cellA, cellB, cellC, cellD, cellE} from "./PropertiesTableCell";

/*
    This component is not meant to be used as a standalone business component.
    It should be supplied with a list/rows (in children), so upper level component should be providing that.
    So for example, compose that (properties list/table) with other components related to PropertiesTable,
    like PropertiesTableRow (list/table items)
 */

interface IProps {
    children: ReactNode;
    sort: IPropertySortOption | null;
    toggleSort: (fieldName: IPropertySortableField) => void;
}

interface IColumn {
    fieldName: string;
    text: string;
    additionalCss: SerializedStyles;
}

const columns: IColumn[] = [
    {
        fieldName: "plan",
        text: "Rzut",
        additionalCss: cellA
    },
    {
        fieldName: "rooms",
        text: "Pokoje",
        additionalCss: cellB
    },
    {
        fieldName: "floor",
        text: "Piętro",
        additionalCss: cellC
    },
    {
        fieldName: "area",
        text: "Metraż",
        additionalCss: cellD
    },
    {
        fieldName: "price",
        text: "Cena",
        additionalCss: cellE
    }
];

const sortableFields = ["number", "rooms", "area", "floor", "price"];

export function PropertiesTable(props: IProps) {
    const {children, sort, toggleSort} = props;

    return (
        <div css={tableWrap}>
            <TableRow>
                {columns.map((column) => {
                    const {fieldName, text, additionalCss} = column;
                    const isSortable = sortableFields.includes(fieldName);

                    const onClick = () => {
                        if (isSortable) {
                            toggleSort(fieldName as IPropertySortableField);
                        }
                    };

                    return (
                        <HeaderCell key={`mpth${fieldName}`} variant="body_copy_1" css={additionalCss} as="div" onClick={onClick} isSortable={isSortable}>
                            {text}
                            {isSortable && <SortIcon value={getSortValue(sort, fieldName as IPropertySortableField)} />}
                        </HeaderCell>
                    );
                })}
            </TableRow>
            {children}
        </div>
    );
}

/*
    Styles
 */

const tableWrap = css`
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    ${mb(3)};
`;

const HeaderCell = styled(Text)<{isSortable?: boolean}>`
    ${({isSortable}) => css`
        display: flex;
        justify-content: center;
        column-gap: ${crs(1)};

        ${isSortable &&
        css`
            cursor: pointer;
        `}
    `}
`;

const TableRow = styled.div<{variant?: "light" | "dark"}>`
    ${({theme, variant}) => css`
        ${variant &&
        css`
            background: ${variant === "light" ? theme.colors.gray[100] : theme.colors.gray[200]};
        `}
        display: flex;
        justify-content: center;
        height: 54px;
        align-items: center;
    `}
`;

/*
    Sub-components
 */
interface ISortIconProps {
    value: ISortValue | null;
}

function SortIcon(props: ISortIconProps) {
    const {value} = props;
    const theme = useTheme();

    return (
        <SortIconWrapper value={value}>
            <ChevronDownIcon size="1.3" fill={theme.colors.gray[700]} />
        </SortIconWrapper>
    );
}

const SortIconWrapper = styled.div<{value: ISortValue | null}>`
    ${({value}) => css`
        & > svg {
            opacity: ${value === null ? "0.5" : "1"};
            transform: ${value === "asc" ? `rotate(180deg) scale(1.3)` : ""};
        }
    `}
`;

/*
    Utils
 */
function getSortValue(currentSortOption: IPropertySortOption | null, fieldName: IPropertySortableField) {
    if (currentSortOption && currentSortOption.fieldName === fieldName) {
        return currentSortOption.value;
    }

    return null;
}
