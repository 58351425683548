import {ReactNode} from "react";
import {css} from "@emotion/react";

import {calculateRemSize, mb, onDesktop} from "@pg-design/helpers-css";

/**
 * This component is not meant to be used as a standalone business component.
 * It should be supplied with a list/items (in children), so upper level component should be providing that,
 * and combining that (list) with other components related to PropertiesList, like PropertiesListItem
 */

interface IProps {
    children: ReactNode;
    className?: string;
}
export function OfferModalPropertiesList(props: IProps) {
    const {children, className} = props;

    return (
        <div css={tilesList} className={className}>
            {children}
        </div>
    );
}

const tilesList = css`
    display: grid;
    grid-template-columns: 1fr;
    column-gap: ${calculateRemSize(1.5)};
    row-gap: ${calculateRemSize(1.5)};
    ${mb(3)};

    ${onDesktop(css`
        grid-template-columns: repeat(2, 1fr);
        column-gap: ${calculateRemSize(2)};
        row-gap: ${calculateRemSize(2)};
    `)};

    @media screen and (min-width: 1700px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;
