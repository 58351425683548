import {useState} from "react";

import {FakeSelectWithModal} from "@pg-design/fake-select-with-modal";
import {IRange} from "@pg-design/inputs";

import {SearchFiltersRooms} from "../../../search/components/filters/SearchFiltersRooms";
import {getRoomLabel} from "../../../search/utils/room_field_data_translations";

interface IProps {
    initialValue?: IFilterValue;
    onConfirm?: (value: IFilterValue) => void;
    handleClear?: () => void;
    className?: string;
}

type IFilterValue = IRange<number | "">;
const emptyValue: IFilterValue = {lower: "", upper: ""};

export function RoomFilter(props: IProps) {
    const {initialValue, onConfirm, handleClear, className} = props;
    const [value, setValue] = useState<IFilterValue>(initialValue ? initialValue : emptyValue);

    const handlers = {
        confirm: (values: IFilterValue) => {
            if (onConfirm) {
                onConfirm(values);
            }
        },
        clear: () => {
            if (handleClear) {
                setValue(emptyValue);
                handleClear();
            }
        }
    };

    return (
        <FakeSelectWithModal
            label={getRoomLabel(value, "Pokoje")}
            isEmpty={!initialValue || (initialValue && initialValue.lower === "" && initialValue.upper === "")}
            onClear={handlers.clear}
            className={className}
        >
            {(props) => (
                <SearchFiltersRooms
                    value={value}
                    name="rooms"
                    onChange={(_, value) => {
                        setValue(value);
                    }}
                    onAfterChange={() => {
                        //NA
                    }}
                    toggleDropdown={() => {
                        props.close();
                    }}
                    onSubmitSingle={() => {
                        handlers.confirm(value);
                        props.close();
                    }}
                    clearField={handlers.clear}
                    showFooter
                />
            )}
        </FakeSelectWithModal>
    );
}
