import {useState} from "react";

import {FakeSelectWithModal} from "@pg-design/fake-select-with-modal";

import {FilterFooter} from "../../../search/components/filters/SearchFiltersFooter";
import {FloorChoicesSwitch} from "../../../search/components/FloorChoicesSwitch";
import {getFloorChoiceLabel} from "../../../search/utils/get_label";

interface IProps {
    id: string;
    initialValue?: IFilterValue;
    onConfirm?: (value: IFilterValue) => void;
    handleClear?: () => void;
    className?: string;
}

type IFilterValue = number[];
const emptyValue: IFilterValue = [];
const name = "floors";

export function FloorFilter(props: IProps) {
    const {id, initialValue, onConfirm, handleClear, className} = props;
    const [value, setValue] = useState<IFilterValue>(initialValue ? initialValue : emptyValue);

    const handlers = {
        confirm: (values: IFilterValue) => {
            if (onConfirm) {
                onConfirm(values);
            }
        },
        clear: () => {
            setValue(emptyValue);

            if (handleClear) {
                handleClear();
            }
        }
    };

    return (
        <FakeSelectWithModal label={getFloorChoiceLabel(value)} isEmpty={value.length === 0} onClear={handlers.clear} className={className}>
            {(props) => (
                <>
                    <FloorChoicesSwitch
                        name={name}
                        id={id}
                        onAfterChange={() => {
                            //NA
                        }}
                        value={value}
                        onChange={(_, value) => {
                            setValue(value);
                        }}
                    />

                    <FilterFooter
                        onCancel={() => {
                            handlers.clear();
                            props.close();
                        }}
                        onSave={() => {
                            handlers.confirm(value);
                            props.close();
                        }}
                    />
                </>
            )}
        </FakeSelectWithModal>
    );
}
