import React, {useState} from "react";
import {css, Theme} from "@emotion/react";

import {elevation} from "@pg-design/elevation";
import {borderRadius, flex, mb, mt, p, pb} from "@pg-design/helpers-css";
import {ChevronDownIcon, ChevronUpIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {IUserPoi} from "../types/IUserPoi";
import {SinglePoiForm} from "./SinglePoiForm";

interface IProps {
    offer: {
        geo_point: {
            coordinates: [number, number];
        };
    };
    hideHeader?: boolean;
    disableCollapsible?: boolean;
    className?: string;
    onChange?: (userPoi: IUserPoi) => void;
}

export const SinglePoi = (props: IProps) => {
    const [collapsed, setCollapsed] = useState(false);

    const targetCoords = props.offer?.geo_point.coordinates;

    return (
        <div css={userPoiStyle} className={props.className}>
            {props.hideHeader ? null : (
                <div css={heading}>
                    <Text as="span" variant="headline_6">
                        Moje miejsce
                    </Text>
                    {props.disableCollapsible ? null : (
                        <span css={toggleIcon} onClick={() => setCollapsed((prev) => !prev)}>
                            {collapsed ? <ChevronDownIcon size="2" /> : <ChevronUpIcon size="2" />}
                        </span>
                    )}
                </div>
            )}

            <div css={collapsed && !props.disableCollapsible ? hide : null}>
                <div css={userPoiForm}>
                    <SinglePoiForm onChange={props.onChange} targetCoords={targetCoords} />
                </div>
            </div>
        </div>
    );
};

const userPoiStyle = (theme: Theme) => css`
    background-color: #fff;
    width: 100%;
    ${mt(6)};

    @media (min-width: ${theme.breakpoints.md}) {
        width: 26.4rem;
        ${elevation()};
        ${borderRadius(2)};
        ${p(2)};
        ${mt(0)};
    }
`;

const heading = css`
    ${flex("center", "space-between")};
    user-select: none;
    ${mb(3)};
`;

const toggleIcon = (theme: Theme) => css`
    cursor: pointer;

    @media (max-width: ${theme.breakpoints.md}) {
        display: none;
    }
`;

const hide = css`
    height: 0;
    overflow: hidden;
`;

const userPoiForm = (theme: Theme) => css`
    @media (max-width: ${theme.breakpoints.md}) {
        ${pb(4)};
        ${mb(3)};
    }
`;
