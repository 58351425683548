import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {ApplicationSourceSection} from "../../application/utils/ApplicationSource";
import {IOfferModalName} from "../types/OfferModalName";
import {IOfferModalState} from "../types/OfferModalState";
import {offerModalsSliceInitialState} from "./offer_modals_slice_initial_state";

export enum PropertyListType {
    TABLE = "table",
    TILES = "tiles"
}

export const offerModalsSlice = createSlice({
    name: "offerModals",
    initialState: offerModalsSliceInitialState,
    reducers: {
        showOfferModal: (
            state,
            action: PayloadAction<{
                modalName: IOfferModalName;
                offerId: number;
                sourceSection?: ApplicationSourceSection;
                filters?: IOfferModalState["filters"];
            }>
        ) => {
            const {modalName, offerId, sourceSection, filters} = action.payload;

            state[modalName].isOpen = true;
            state[modalName].offerId = offerId;
            state[modalName].sourceSection = sourceSection ? sourceSection : null;
            state[modalName].filters = filters ? filters : state[modalName].filters;
            state[modalName].page = 1;
        },
        hideOfferModal: (state, action: PayloadAction<{modalName: IOfferModalName}>) => {
            const {modalName} = action.payload;
            state[modalName].isOpen = false;
        },
        navToNextModal: (state, action: PayloadAction<{modalName: IOfferModalName; offerId: number}>) => {
            const {modalName, offerId} = action.payload;
            state[modalName].offerId = offerId;
            state[modalName].position = state[modalName].position + 1;
            state[modalName].page = 1;
        },
        navToPrevModal: (state, action: PayloadAction<{modalName: IOfferModalName; offerId: number}>) => {
            const {modalName, offerId} = action.payload;
            state[modalName].offerId = offerId;
            state[modalName].position = state[modalName].position - 1;
            state[modalName].page = 1;
        },
        setModalQueryParams: (state, action: PayloadAction<{modalName: IOfferModalName; listingPage: number; listingPageSize: number}>) => {
            const {modalName, listingPage, listingPageSize} = action.payload;
            state[modalName].listingPage = listingPage;
            state[modalName].listingPageSize = listingPageSize;
        },
        loadNextPage: (state, action: PayloadAction<{modalName: IOfferModalName}>) => {
            const {modalName} = action.payload;
            state[modalName].page = state[modalName].page + 1;
        },
        resetPropertyList: (state, action: PayloadAction<{modalName: IOfferModalName}>) => {
            const {modalName} = action.payload;
            state[modalName].page = 1;
        },
        setOfferModalFilters: (state, action: PayloadAction<{modalName: IOfferModalName; filters: IOfferModalState["filters"]}>) => {
            const {modalName, filters} = action.payload;
            state[modalName].filters = filters;
            state[modalName].page = 1;
        },
        setOfferModalSort: (state, action: PayloadAction<{modalName: IOfferModalName; sort: IOfferModalState["sort"]}>) => {
            const {modalName, sort} = action.payload;
            state[modalName].sort = sort;
            state[modalName].page = 1;
        },
        setOfferModalType: (state, action: PayloadAction<{modalName: IOfferModalName; type: IOfferModalState["type"]}>) => {
            const {modalName, type} = action.payload;
            state[modalName].type = type;
        }
    }
});

export const {
    showOfferModal,
    hideOfferModal,
    navToNextModal,
    navToPrevModal,
    setModalQueryParams,
    loadNextPage,
    resetPropertyList,
    setOfferModalFilters,
    setOfferModalSort,
    setOfferModalType
} = offerModalsSlice.actions;
