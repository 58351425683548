import {useEffect, useState} from "react";

export interface IInfiniteList<ListItemValue> {
    items: IInfiniteListItem<ListItemValue>[];
    canLoadMore: boolean;
    totalPageCount: number;
}

export interface IInfiniteListItem<ListItemValue> {
    data: ListItemValue;
    page: number;
}

export function useInfiniteList<ListItemValue>(
    queryData:
        | {
              results: ListItemValue[];
              page: number;
              page_size: number;
              count: number;
          }
        | undefined,
    handleLoadMore: () => void,
    shouldLoadMore: boolean,
    isUninitialized: boolean
): IInfiniteList<ListItemValue> {
    const [items, setItems] = useState<ListItemValue[]>([]);

    const totalPageCount = queryData ? Math.ceil(queryData.count / queryData.page_size) : 0;
    const queryPage = queryData ? queryData.page : 1;
    const canLoadMore = isUninitialized || queryPage < totalPageCount;

    useEffect(() => {
        if (queryData && queryPage === 1) {
            setItems(queryData.results);
        }

        if (queryData && queryPage !== 1) {
            setItems((prev) => [...prev, ...queryData.results]);
        }
    }, [queryData]);

    useEffect(() => {
        if (canLoadMore && shouldLoadMore) {
            handleLoadMore();
        }
    }, [shouldLoadMore, queryPage, totalPageCount]);

    return {
        items: getListItems(items, queryPage),
        canLoadMore,
        totalPageCount
    };
}

function getListItems<ListItemValue>(items: ListItemValue[], currentPage: number): IInfiniteListItem<ListItemValue>[] {
    return items.map((item) => ({data: item, page: currentPage}));
}
