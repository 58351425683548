// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import {css} from "@emotion/react";
import styled from "@emotion/styled";

import {flexAbsoluteCenter} from "@pg-design/helpers-css";

interface IProps {
    sorted?: boolean;
}

export const PropertiesTableCell = styled.div<IProps>`
    ${({sorted, theme}) => css`
        ${flexAbsoluteCenter};
        flex-direction: column;
        min-height: 54px;

        ${sorted &&
        css`
            //#e1d7cd
            background: ${theme.colors.background[200]};
        `}
    `}
`;

export const cellA = css`
    flex: 1 0 10%;
`;

export const cellB = css`
    flex: 1 0 15%;
`;

export const cellC = css`
    flex: 2 1 20%;
`;

export const cellD = css`
    flex: 2 1 25%;
`;
export const cellE = css`
    flex: 5 1 30%;
`;
