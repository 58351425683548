import React from "react";
import {css, Theme} from "@emotion/react";

import {flex, h100, mt, onDesktop, p, w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {OpenStreetMapsWithPoi} from "../../../maps/components/map_with_poi/OpenStreetMapsWithPoi";
import {PoiSwitcher} from "../../../maps/components/PoiSwitcher";
import {SinglePoi} from "../../../maps/components/SinglePoi";
import {useSinglePoiMarker} from "../../../maps/hooks/use_single_poi_marker";
import {getHasCountryPois} from "../../../maps/utils/get_has_country_pois";
import {ViewType} from "../../../view_type/ViewType";
import {IOfferModalOffer} from "../../types/OfferModalOffer";
import {IOfferDetail} from "../types/IOfferDetail";

interface IProps {
    className?: string;
    offer: IOfferDetail | IOfferModalOffer;
    area?: {
        center: {
            coordinates: [number, number];
        };
        polygon: {
            coordinates: number[][][];
        };
        region: number;
    };
    property?: {
        id: number;
        for_sale: boolean;
    };
    disablePoiSwitch?: boolean;
    defaultZoom?: number;
    disableClusterMarkers?: boolean;
}

export const OfferDetailLocationMap = (props: IProps) => {
    const {offer} = props;

    // we only need this to be local state, instead of global redux user's POI
    const {markers, onSinglePoiChange} = useSinglePoiMarker(offer);

    const hasCountryPois = getHasCountryPois(offer.region.country);

    return (
        <div css={mapContainerStyle} className={props.className}>
            <OpenStreetMapsWithPoi
                offer={{
                    ...props.offer,
                    geo_area: {
                        coordinates: {
                            coordinates: "geo_area" in offer ? offer.geo_area?.coordinates : []
                        }
                    }
                }}
                viewType={props.property ? ViewType.PROPERTY : ViewType.OFFER}
                polygon={props.area && props.area.polygon}
                region={props.area && props.area.region}
                disableInitiallyOpenedPoiId
                mapConfig={{
                    scrollWheelZoom: false,
                    fitBounds: true,
                    fitBoundsDefaultZoom: props.defaultZoom
                }}
                mobilePoiModalTriggerPosition="center"
                customMarkers={markers}
                clusterMarkers={!props.disableClusterMarkers}
                disablePoiSwitch={!hasCountryPois || props.disablePoiSwitch}
            >
                {({setCheckedPoiTypes, checkedPoiTypes, map}) => (
                    <div css={mapRendererWrapper}>
                        <div css={mapRendererMapColumn}>{map}</div>
                        {hasCountryPois && (
                            <div css={mapRendererAside}>
                                <Text as="div" variant="mini_header">
                                    Ważne miejsca
                                </Text>
                                <PoiSwitcher
                                    checkedPoiTypes={checkedPoiTypes}
                                    onChange={setCheckedPoiTypes}
                                    hideHeader
                                    disableCollapsible
                                    css={mapRendererPoiSwitcherStyle}
                                />

                                <Text as="div" variant="mini_header" css={[mt(3)]}>
                                    Moje miejsce
                                </Text>
                                <SinglePoi onChange={onSinglePoiChange} offer={offer} hideHeader disableCollapsible css={mapRendererUserPoiStyle} />
                            </div>
                        )}
                    </div>
                )}
            </OpenStreetMapsWithPoi>
        </div>
    );
};
const mapContainerStyle = (theme: Theme) => css`
    position: relative;
    height: 58rem;

    @media (min-width: ${theme.breakpoints.md}) {
        height: 55rem;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        height: 68rem;
    }
`;

const mapRendererWrapper = css`
    ${flex()};
    ${w100};
    ${h100};
`;

const mapRendererMapColumn = css`
    position: relative;
    flex: 1 1 100%;
`;

const mapRendererAside = css`
    display: none;

    ${onDesktop(css`
        display: block;
        background-color: #fff;
        flex: 0 0 30rem;
        ${p(3, 2)};
        overflow: auto;
    `)};
`;

const mapRendererPoiSwitcherStyle = css`
    ${onDesktop(css`
        box-shadow: none;
        border-radius: 0;
        width: 100%;
    `)};
`;

const mapRendererUserPoiStyle = css`
    ${onDesktop(css`
        box-shadow: none;
        border-radius: 0;
        width: 100%;
        ${p(2, 0, 0, 0)};
    `)};
`;
